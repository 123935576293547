<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Payment Type</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="paymentTypeInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "FormPaymentType",
  data() {
    return {
      name: "Form Payment Type",
      form: {
        name: "",
      },
      isSubmitting: false,
      errors: {},
      edit: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("payment_type/" + id)
        .then((result) => {
          this.form.name = result.data.data.name;
          this.form.phone = result.data.data.phone;
          this.form.address = result.data.data.address;
          this.form.tempo = result.data.data.tempo;
          this.form.status_tempo = result.data.data.status_tempo;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    paymentTypeInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + "payment_type/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Payment Type has been created!");
            this.$router.push({ name: "PaymentType" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        let id = this.$route.params.id;
        axios
          .post(
            this.$store.state.api + "payment_type/update/" + id,
            this.form,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$noty.success("Your paymentType has been updated!");
            this.$router.push({ name: "PaymentType" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
